<template>
  <div class="success-container">
    <h2>Pembayaran Berhasil!</h2>
    <p>Terima kasih telah membeli naskah ini.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  async mounted() {
    const email = localStorage.getItem('order_email');
    if (email) {
      try {
        await axios.post('https://my.qrcbn.com/api/affiliate/update-status', { email });
        localStorage.removeItem('order_email'); // Hapus email setelah update status
      } catch (error) {
        console.error("Gagal update status order", error.response ? error.response.data : error.message);
      }
    }
  }
}
</script>